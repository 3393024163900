.jump-to-button {
  margin-bottom: 20px;
}

.jump-to-select {
  width: 100px;
  height: 40px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  background-color: #5A5A5A; 
  color: white;
  outline: none; 
  transition: background-color 0.3s;
}

.jump-to-select:hover {
  background-color: #005f5f; 
}
  